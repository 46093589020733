import {useRecoilState, useSetRecoilState} from 'recoil';
import {modals} from '../states/modals';
import {useEffect, useState} from 'react';
import {route} from '../constants/routes';
import {useNavigate} from 'react-router-dom';
import {getPdfDownloadUrl, generatePdf as generatePdfRequest} from '../queries/groups';
import {pdf_state} from '../states/pdf';
import {useChangeDistribution} from './auth';
import {distributionT} from '../types/tickets';

export const useLoggedModals = () => {
  const setModals = useSetRecoilState(modals);

  const checkLogged = (lastSessionDate?: string, distribution?: string) => {
    if (distribution === distributionT.pdf) return;
    if (!lastSessionDate) return setModals({helpExpand: false, subsequent: false, onboarding: true});
    setModals({helpExpand: true, subsequent: true, onboarding: false});
  };
  return checkLogged;
};

export type useHelpModalT = {
  expanded?: boolean;
};

export const useHelpModal = () => {
  const [_modals, setModals] = useRecoilState(modals);

  useEffect(() => {
    const click = () => {
      if (_modals.helpExpand) setModals((prev) => ({...prev, helpExpand: undefined}));
    };
    document.addEventListener('click', click);
    return () => document.removeEventListener('click', click);
  }, [_modals.helpExpand]);
  const restoreSubsequent = () => {
    setModals((prev) => ({...prev, subsequent: false}));
  };
  const restoreOnboarding = () => {
    setModals((prev) => ({...prev, onboarding: false}));
  };

  const triggerFlow = () => {
    setModals((prev) => {
      if (prev.triggerOnboarding === 'start') return {...prev, triggerOnboarding: 'continue'};
      if (prev.triggerOnboarding === 'end') return {...prev, triggerOnboarding: 'start'};
      if (prev.triggerOnboarding?.includes('continue'))
        return {...prev, triggerOnboarding: `continue${new Date().getTime()}`};
      return {...prev, triggerOnboarding: 'start'};
    });
  };
  return {
    helpModal: {expanded: _modals.helpExpand},
    subsequent: _modals.subsequent,
    triggerFlow,
    restoreSubsequent,
    uploadTriggered: _modals.triggerUpload,
    isOnboarding: _modals.onboarding,
    restoreOnboarding,
    triggerOnboarding: _modals.triggerOnboarding,
  };
};

export enum flowSteps {
  methods = 'methods',
  bulk = 'bulk',
  individual = 'individual',
  upload = 'upload',
  end = 'end',
}

export const useModalsFlow = () => {
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [step, setStep] = useState<string>();
  const [_modals, setModals] = useRecoilState(modals);

  const goMethods = () => setStep(flowSteps.methods);
  const choseBulk = () => setStep(flowSteps.bulk);
  const choseIndividual = () => setStep(flowSteps.individual);
  const goUpload = () => {
    setStep(flowSteps.upload);
    setModals((prev) => ({...prev, triggerUpload: true, triggerOnboarding: 'end'}));
  };
  const onFinish = () => {
    setModals((prev) => ({...prev, triggerOnboarding: 'end'}));
    setStep(flowSteps.end);
  };

  useEffect(() => {
    setShowOnboarding(_modals.onboarding);
  }, []);
  useEffect(() => {
    if (_modals.triggerOnboarding === 'start') {
      setModals((prev) => ({...prev, triggerUpload: false}));
      goMethods();
      setShowOnboarding(true);
    }
    if (_modals.triggerOnboarding?.includes('continue')) {
      setModals((prev) => ({...prev, triggerUpload: false}));
      setShowOnboarding(true);
    }
  }, [_modals.triggerOnboarding]);

  const onCloseFlowModals = () => {
    setShowOnboarding(false);
  };
  return {showOnboarding, step, choseBulk, choseIndividual, goMethods, goUpload, onFinish, onCloseFlowModals};
};

export const useDistribution = (checkAndGenerate?: (individual: boolean) => Promise<boolean>) => {
  const setModals = useSetRecoilState(modals);
  const [isUploading, setIsUploading] = useState(false);
  const [success, setSuccess] = useState<any>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [method, setMethod] = useState<string>();
  const [pdfType, setPdfType] = useState<string>();
  const navigate = useNavigate();
  const {updateDistribution} = useChangeDistribution();

  const goGroups = () => navigate(route.groups.path);
  const selectPdf = () => {
    setMethod(distributionT.pdf);
    updateDistribution(distributionT.pdf);
    setModals({helpExpand: false, subsequent: true, onboarding: false});
  };
  const selectEmail = () => {
    setMethod(distributionT.email);
    updateDistribution(distributionT.email);
    goGroups();
  };
  const cancelUploading = () => {
    setIsUploading(false);
    setSuccess(null);
    setError(null);
    setPdfType('');
    setLoading(false);
  };

  const generatePdf = (groupId?: string, individual?: boolean) => async () => {
    try {
      setModals({helpExpand: false, subsequent: false, onboarding: false});
      setPdfType(individual ? 'individual' : 'single');
      setLoading(true);
      if (!groupId) return;
      setIsUploading(true);
      const res = await checkAndGenerate?.(individual || false);
      setSuccess(res);
    } catch (e) {
      console.log('generate pdf error', e);
      setError(e);
    } finally {
      setLoading(false);
    }
  };
  return {
    goGroups,
    selectPdf,
    selectEmail,
    method,
    generatePdf,
    success,
    loading,
    isUploading,
    cancelUploading,
    pdfType,
    error,
  };
};

export const usePdf = (groupId?: string, initialLoad?: boolean, pdfContent?: boolean) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useRecoilState(pdf_state);

  const checkAndGenerate = async () => {
    try {
      const pdf1 = await download(true);
      const pdf2 = await download(false);

      if (!pdf1 || !pdf2) return await regenerate();
      return true;
    } catch (e) {
      return false;
    }
  };

  const regenerate = async () => {
    if (!groupId) return false;
    setLoading(true);
    try {
      await generatePdfRequest({groupId});
      await download(true);
      await download(false);
      return true;
    } catch (e) {
      console.error('generate err:', e);
      return false;
    } finally {
      setLoading(false);
    }
  };
  const download = async (individual?: boolean) => {
    if (!groupId) return;
    try {
      setLoading(true);
      const fileResp = await getPdfDownloadUrl({groupId, individual});
      const fileUrl = fileResp?.body.fileUrl;
      const key = individual ? 'individual' : 'single';
      const fileName = fileResp?.body.filename || (individual ? 'individual_pdfs.zip' : 'tickets.pdf');
      setFiles((prev) => ({
        ...(prev || {}),
        [key]: {name: fileName, url: fileUrl},
        groupId,
      }));
      return true;
    } catch (e) {
      console.log('pdf err:', e);
      return false;
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (initialLoad) {
      if (!files?.individual?.url || !files?.single?.url) regenerate();
    }
  }, [initialLoad]);

  useEffect(() => {
    if (pdfContent) {
      download(true);
      download(false);
    }
  }, [pdfContent]);

  useEffect(() => {
    // const initialDownload = async () => {
    //   if (!files?.individual?.url || !files?.single?.url || groupId !== files?.groupId) {
    //     await checkAndGenerate();
    //   }
    // };
    // initialDownload();
  }, [groupId, files?.groupId]);
  return {
    downloadIndividual: () => download(true),
    downloadSingle: () => download(false),
    url_individual: files?.individual?.url,
    name_individual: files?.individual?.name,
    url_single: files?.single?.url,
    name_single: files?.single?.name,
    loading,
    regenerate,
    checkAndGenerate,
  };
};
