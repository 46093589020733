import React from 'react';
import {FooterWrapper, LogoSectionWrapper, PageWrapper, RightPart} from './styles';
import {LoginForm} from './LoginForm';
import {useLoginRes} from '../../hooks/auth';
import {LogoSection} from './LogoSection';

type LogInPropsT = {
  authData: useLoginRes;
  successUpdated?: boolean;
};

export const LogInPage: React.FC<LogInPropsT> = ({authData, successUpdated}) => {
  return (
    <PageWrapper>
      <LogoSectionWrapper>
        <LogoSection />
      </LogoSectionWrapper>
      <RightPart>
        <LoginForm authData={authData} successUpdated={successUpdated} />
        <FooterWrapper />
      </RightPart>
    </PageWrapper>
  );
};
