export const HELP_TITLE = 'How To Use Your Group Ticketing Portal';
export const HELP = {
  pt1: `Below you will see details about your group tickets.`,
  pt2: `If you are distributing by email, add your contact names and emails (via bulk upload or by entering them individually) and assign the tickets they should receive. Once you have assigned tickets, you can send them to your contacts.`,
  pt3: `If you are distributing by PDF, you can download your file (individual PDFs per ticket in one zip file or one PDF containing all tickets) or regenerate if you need to update it.`,
  pt4: `You cannot change your ticket distribution method once PDF tickets have been generated or emails sent.`,
  pt5: `You can toggle between different orders by selecting the order dropdown near the top-left of the page.`,
};

export const CONTACTS_ADDED = `Click “Continue” to contact details, edit contact information, change quantities, or upload more contacts to the portal. You can now go through the entries for each ticket and review to see if the details are correct. Then you may either send the code individually by clicking on the row, or you can bulk send all the tickets by clicking on the “Bulk Send Tickets” at the bottom of the page.`;

export const SwitchModal = {
  pdf: {
    okButton: 'Switch to PDF',
    title: 'Are you sure you want to change your distribute type to PDF?',
    description:
      'If you do this you cannot resend those tickets electronically. You can only create new tickets to send by printing.',
  },
  email: {
    okButton: 'Switch to Email',
    title: 'Are you sure you want to change your distribute type to email?',
    description:
      'If you do this you cannot resend those tickets you have printed out. You can only create new tickets to send electronically.',
  },
};
