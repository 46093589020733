import styled from 'styled-components';
import {Typography} from 'antd';
import {Button} from '../Button';
import {Link} from 'react-router-dom';
import {Media} from '../theme/breakpoints';
const {Text} = Typography;

export const FooterWrapper = styled.footer<{zIndex?: number}>`
  height: 80px;
  position: static;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({theme}) => theme.palette.background.lightGold};
  border-top: 1px solid ${({theme}) => theme.palette.common.brown};
  ${({zIndex}) => zIndex && `z-index: ${zIndex};`};
  ${Media.down.l} {
    flex-direction: column;
  }
`;

export const FooterText = styled(Text)`
  font-size: 12px;
  margin-left: 190px;
  ${Media.down.l} {
    margin: 0 auto;
  }
`;

export const FooterCenterText = styled(Text)`
  font-size: 12px;
  margin: 0 auto;
`;

export const EmptyHeaderContainer = styled.header`
  display: flex;
  justify-content: center;
  padding-top: 50px;
`;

export const HeaderLogoBig = styled.img`
  width: 273px;
  height: 56px;
  margin: 0 auto;
  display: block;
`;

export const HeaderContainer = styled.header`
  width: 100%;
  background: ${({theme}) => theme.palette.background.midnightBlue};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  height: 83px;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 80px;
  ${Media.down.l} {
    padding: 0px 10px;
    flex-flow: column wrap;
  }
`;

export const NoopBlock = styled.div``;

export const HeaderLink = styled(Link)`
  user-select: none;
  &::selection {
    background: transparent;
  }
  outline-style: none;
  &:focus {
    background: transparent;
  }
`;

export const HeaderLogoMedium = styled.img`
  width: 186px;
  height: 38px;
  transition: 0.2s all;
  user-select: none;
  &::selection {
    background: transparent;
  }
  &:hover {
    transform: scale(1.07);
  }
  ${Media.down.l} {
    width: 93px;
    height: 19px;
  }
`;

export const HeaderLeftSide = styled.div``;

export const HeaderDropDown = styled.div`
  .anticon {
    color: white;
  }
`;

export const HeaderRightSide = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  margin-left: 45px;
  svg {
    width: 22px;
    height: 22px;
  }
`;

export const SettingsButton = styled(Button).attrs({
  type: 'default',
})`
  margin-right: 14px;
  border: none;
  background-color: ${({theme}) => theme.palette.background.midnightBlue};
  :hover {
    opacity: 0.7;
    background-color: ${({theme}) => theme.palette.background.midnightBlue};
  }
`;

export const LogoutButton = styled(Button).attrs({
  type: 'primary',
})`
  width: 98px;
  ${Media.down.l} {
    margin-bottom: 3px;
  }
`;

export const ReshareAccessButton = styled(Button)`
  font-size: 16px;
  line-height: 20px;
  width: 161px;
  height: 43px;
  margin-right: 141px;
  ${Media.down.l} {
    margin: 0 auto;
  }
`;

export const HeaderRightText = styled(Text)<{changeMargin: boolean}>`
  font-size: 14px;
  color: ${({theme}) => theme.palette.background.white};
  margin-right: ${({changeMargin}) => (changeMargin ? '0' : '-20px')};
`;

export const HeaderLeftText = styled(Text)`
  font-size: 14px;
  margin-right: 16px;
  color: ${({theme}) => theme.palette.background.white};
  font-weight: 300;
  letter-spacing: 1px;
`;

export const HeaderActiveMenuItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
