import React from 'react';
import styled from 'styled-components';
import {buttonReset} from '../../helpers/common';
import {SvgIcon} from '../Common/Icon';
import logo from '../../assets/logo-gold.png';

type PropsT = {
  goBack?: () => void;
  handleLogout?: () => void;
};

export const HeaderComponent: React.FC<PropsT> = ({goBack, handleLogout}) => {
  return (
    <Wrapper>
      {goBack ? (
        <IconButton onClick={goBack} pos="left">
          <SvgIcon type="arrowLeft" />
        </IconButton>
      ) : (
        <div />
      )}
      <LogoImage src={logo} />
      {handleLogout && (
        <IconButton onClick={handleLogout} pos="right">
          <SvgIcon type="logout" />
        </IconButton>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  background: #041c2c;
`;

const IconButton = styled.button<{pos: 'left' | 'right'}>`
  ${buttonReset}
  width: 48px;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  ${({pos}) => {
    if (pos === 'left')
      return `
      justify-self: flex-start;
    `;
    if (pos === 'right')
      return `
      justify-self: flex-end;
    `;
  }};
`;

const LogoImage = styled.img`
  width: 106px;
  height: 25px;

  align-self: center;
`;
